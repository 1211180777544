@font-face {
  font-family: Norwester;
  src: url(../assets/fonts/norwester.otf);
}

@font-face {
  font-family: Poppins-Light;
  src: url(../assets/fonts/Poppins-Light.ttf);
}

body {
  font-family: Poppins-Light !important;
  background-color: #6a74ff !important;
}


/* MAIN CONTAINER*/
.banner { 
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
    background-image: url('../assets/images/blue-curve.svg');
    background-position: top;
    background-size: cover;
}

/* NAVBAR */
.banner .navbar-content {
    height: 10%;
}

.banner .navbar {
    padding-top: 15px;
    border: 0;
    z-index: 2;
}

.banner .navbar-brand {
    color: #fff !important;
    font-family: Norwester;
    font-size: 1.8em;
    font-weight: 700;
    margin-left: 5%;
}

.banner .navbar-nav {
    margin-right: 5%;
}

.banner .navbar-nav .nav-link {
    color: #fff !important;
    font-size: 1.2em;
}

/* PAGE CONTENT */
.banner .content {
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 90%;
}


/* INDEX PAGE*/

.banner .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner .info .app-title {
  font-family: Norwester;
}

.banner .info .app-subtitle {
  font-family: Poppins-Light;
  font-size: 24px;
  text-align: center;
}

.banner .info .index-img {
  width: 50%;
}

.banner .info h1 {
    font-size: 2.5em;
    font-weight: 700;
    color: #fff;
    letter-spacing: 2px;
}

.banner .info p {
    font-size: 2em;
    font-weight: 500;
    color: #FFFAF0;
    letter-spacing: 2px;
}

.banner .info button {
    color: #5864ff;
    padding: 10px 20px;
    font-size: 1.5em;
    font-weight: 600;
}

.banner .right-panel button {
    margin-right: 50%;
    transform: translateX(-50%);
    color: #5864ff;
    padding: 10px 20px;
    font-size: 1.5em;
    font-weight: 600;
}

.index-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0rem 5rem;
    margin-bottom: 20px;
  }
  
  .index-form .input-field {
    max-width: 380px;
    width: 100%;
    background-color: #f0f0f0;
    margin: 10px 0;
    height: 55px;
    border-radius: 55px;
    display: grid;
    grid-template-columns: 15% 85%;
    padding: 0 1.5rem 0 0;
    position: relative;
  }
  
  .index-form .input-field i {
    text-align: center;
    line-height: 55px;
    color: #acacac;
    font-size: 1.1rem;
  }

  .index-form .input-field .show-password {
    text-align: center;
    line-height: 55px;
    position: absolute;
    right: 30px;
  }
  
  .index-form .input-field input {
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    font-weight: 600;
    font-size: 1.1rem;
    color: #333;
    height: 100%;
  }
  
  .index-form.input-field input::placeholder {
    color: #aaa;
    font-weight: 500;
  }

/* ABOUT PAGE*/
.banner .about-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: auto 50px;
}

.banner .about-left button {
  color: #5864ff;
  padding: 10px 20px;
  font-size: 1.5em;
  font-weight: 600;
}

.banner .about-left h2 {
  border-bottom: 2px solid #fff;
}

.banner .about-right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner .about-right .about-img {
  width: 50%;
}

  /* HOME PAGE */
.home-section {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-section .home-card {
  height: 85%;
  width: 100%;
}

.home-card .categories {
    width: 100%;
    padding: 0;
    margin-left: 0;
  }
  
  .home-card .categories > div {
    float: left;
    width: 50%;
    padding: 10px 5px;
    font-size: 18px;
    text-align: center;
    border-right: 1px solid rgba(0,0,0,0.1);
    background-color: rgba(255,255,255,0.75);
    cursor: pointer;
    font-weight: 700;
  }
  
  .home-card .categories > div:nth-child(1) {
    color: #2da9e9;
    border-right-color: rgba(45,129,233,0.06);
    border-bottom: 4px solid #2da9e9;
    border-top-left-radius: 5px;
  }
  
  .home-card .categories > div:nth-child(1).active {
    color: #fff;
    background-color: #2da9e9;
    border-bottom: 4px solid rgba(0,0,0,0.15);
  }
  
  .home-card .categories > div:nth-child(2) {
    color: #0ec8a2;
    border-right-color: rgba(14,200,162,0.06);
    border-bottom: 4px solid #0ec8a2;
  }
  
  .home-card .categories > div:nth-child(2).active {
    color: #fff;
    background-color: #0ec8a2;
    border-bottom: 4px solid rgba(0,0,0,0.15);
  }

  /* MOOCs PAGE */
  .banner .mooc-title-section {
    text-align: center !important;
    color: #fff;
  }

  .banner .mooc-title-section .mooc-title {
    font-family: Norwester;
  }

  .banner .mooc-search {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 1.5rem;
    border: 2px solid #fff;
    width: fit-content;
    border-radius: 8px;
    padding: 5px 10px;
    color: #fff;
    background-color: #2da9e9 !important;
  }

  .banner .mooc-search form {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  