/* UNDERSTANDING LOW LEVEL SIGNAL */
.signal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.signal {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 23vh;
}

.signal .text {
    position: absolute;
    font-weight: 700;
    font-size: 1.5em;
    user-select: none;
    pointer-events: none;
}

.signal .text-red {
    color: red;
    animation: animate-text-warning 7.5s linear infinite;
}

.signal .text span {
    position: absolute;
    top: -100px;
    text-transform: uppercase; 
    display: inline-block;
    transform-origin: 0 100px;
}

.signal .big-small {
    animation: animate-img 5s linear infinite;
}

@keyframes animate-text-warning {
    0% {
        transform: rotate(360deg);
        text-shadow: 0 0 16px red;
    }
    12.5% { text-shadow: 0 0 16px orange; }
    25% { text-shadow: 0 0 16px red; }
    37.5% { text-shadow: 0 0 16px orange; }
    50% { text-shadow: 0 0 16px red; }
    62.5% { text-shadow: 0 0 16px orange; }
    75% { text-shadow: 0 0 16px red; }
    87.5% { text-shadow: 0 0 16px orange; }
    100% {
        transform: rotate(0deg);
        text-shadow: 0 0 16px red;
    }
}

@keyframes animate-img {
    0% { width: 85px; height: 85px; }
    12.5% { width: 105px; height: 105px; }
    25% { width: 85px; height: 85px; }
    37.5% { width: 105px; height: 105px; }
    50% { width: 85px; height: 85px; }
    62.5% { width: 105px; height: 105px; }
    75% { width: 85px; height: 85px; }
    87.5% { width: 105px; height: 105px; }
    100% { width: 85px; height: 85px; }
}