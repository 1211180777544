@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
/* COURSE DETAIL CARD*/
.card-box {
  position: relative;
  color: #fff;
  padding: 5px 10px 5px;
  margin: 0px 0px;
  background-color: #00c0ef !important;
}
.card-box:hover {
  text-decoration: none;
  color: #f1f1f1;
}
.card-box:hover .icon i {
  font-size: 80px;
  transition: 1s;
  -webkit-transition: 1s;
}
.card-box .inner {
  padding: 5px 10px 0 10px;
}
.card-box h3 {
  font-size: 27px;
  font-weight: bold;
  margin: 0 0 8px 0;
  white-space: nowrap;
  padding: 0;
  text-align: left;
}
.card-box p {
  font-size: 15px;
}
.card-box .icon {
  position: absolute;
  top: auto;
  bottom: 0px;
  right: 5px;
  z-index: 0;
  font-size: 60px;
  color: rgba(0, 0, 0, 0.15);
}

/* COURSE CARD */
.card-big-shadow {
    max-width: 320px;
    position: relative;
}

.coloured-cards .card {
    margin-top: 30px;
}

.card[data-radius="none"] {
    border-radius: 0px;
}
.card {
    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
    background-color: #FFFFFF;
    color: #252422;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
}

.clickable {
    cursor: pointer;
}

.card .title {
    text-transform: uppercase;
    line-height: 1.1rem;
    font-size: 20px;
    max-height: 5.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.card[data-background="image"] .title, .card[data-background="image"] .stats, .card[data-background="image"] .category, .card[data-background="image"] .description, .card[data-background="image"] .content, .card[data-background="image"] .card-footer, .card[data-background="image"] small, .card[data-background="image"] .content a, .card[data-background="color"] .title, .card[data-background="color"] .stats, .card[data-background="color"] .category, .card[data-background="color"] .description, .card[data-background="color"] .content, .card[data-background="color"] .card-footer, .card[data-background="color"] small, .card[data-background="color"] .content a {
    color: #FFFFFF;
}

.card.card-just-text .content {
    padding: 30px 30px;
    text-align: center;
}
.card .content {
    padding: 10px 10px 5px 10px;
}

.card[data-color="blue"] .category {
    color: #7a9e9f;
}

.card .category, .card .label {
    font-size: 14px;
    margin-bottom: 0px;
}
.card-big-shadow:before {
    bottom: -12%;
    content: "";
    display: block;
    left: -12%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}

.copy {
    position: absolute;
    top: 0px;
    right: 25px;
    font-size: 20px;
    padding: 10px;
}

.students {
    position: absolute;
    top: 0px;
    right: 50px;
    font-size: 20px;
    padding: 10px;
}

.grow:hover {
    font-size: 25px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    cursor: pointer;
}

.delete {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 20px;
    padding: 10px;
}

h4, .h4 {
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.2em;
}
h6, .h6 {
    font-size: 0.9em;
    font-weight: 600;
    text-transform: uppercase;
}
.card .description {
    font-size: 16px;
    color: #66615b;
}
.content-card{
    margin-top:30px;    
}
a:hover, a:focus {
    text-decoration: none;
}

.card[data-color="blue"] {
    background: #b8d8d8;
}
.card[data-color="blue"] .description {
    color: #506568;
}

/* TOTAL COUNT OF COURSES*/
.total {
    border-bottom: 3px solid #0ec8a2;
  }

/* CREATE COURSE FORM */
.course-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 3px solid #0ec8a2;
    padding: 10px 10px;
    border-radius: 8px;
}

.course-form h5 {
    border-bottom: 3px solid #0ec8a2;
    text-transform: uppercase;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
/* USER LIST TABLE */
.user-list tbody td > img {
  position: relative;
  max-width: 50px;
  float: left;
  margin-right: 15px;
}
.user-list tbody td .user-link {
  display: block;
  font-size: 1.25em;
  padding-top: 3px;
  margin-left: 60px;
}
.user-list tbody td .user-subhead {
  font-size: 0.875em;
  font-style: italic;
}

/* TABLES */
.table {
  border-collapse: separate;
}
.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: #eee;
}
.table thead > tr > th {
  border-bottom: 1px solid #c2c2c2;
  padding-bottom: 0;
}
.table tbody > tr > td {
  font-size: 1.1em;
  background: #f5f5f5;
  border-top: 10px solid #fff;
  vertical-align: middle;
  padding: 12px 8px;
}
.table tbody > tr > td:first-child,
.table thead > tr > th:first-child {
  padding-left: 20px;
}
.table thead > tr > th span {
  border-bottom: 2px solid #c2c2c2;
  display: inline-block;
  padding: 0 5px;
  padding-bottom: 5px;
  font-weight: normal;
}
.table thead > tr > th > a span {
  color: #344644;
}
.table thead > tr > th > a span:after {
  content: "\f0dc";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  margin-left: 5px;
  font-size: 0.75em;
}
.table thead > tr > th > a.asc span:after {
  content: "\f0dd";
}
.table thead > tr > th > a.desc span:after {
  content: "\f0de";
}
.table thead > tr > th > a:hover span {
  text-decoration: none;
  color: #2bb6a3;
  border-color: #2bb6a3;
}
.table.table-hover tbody > tr > td {
  transition: background-color 0.15s ease-in-out 0s;
}
.table tbody tr td .call-type {
  display: block;
  font-size: 0.75em;
  text-align: center;
}
.table tbody tr td .first-line {
  line-height: 1.5;
  font-weight: 400;
  font-size: 1.125em;
}
.table tbody tr td .first-line span {
  font-size: 0.875em;
  color: #969696;
  font-weight: 300;
}
.table tbody tr td .second-line {
  font-size: 0.875em;
  line-height: 1.2;
}
.table a.table-link {
  margin: 0 5px;
  font-size: 1.125em;
}
.table a.table-link:hover {
  text-decoration: none;
  color: #2aa493;
}
.table a.table-link.danger {
  color: #fe635f;
}
.table a.table-link.danger:hover {
  color: #dd504c;
}

.table-products tbody > tr > td {
  background: none;
  border: none;
  border-bottom: 1px solid #ebebeb;
  transition: background-color 0.15s ease-in-out 0s;
  position: relative;
}
.table-products tbody > tr:hover > td {
  text-decoration: none;
  background-color: #f6f6f6;
}
.table-products .name {
  display: block;
  font-weight: 600;
  padding-bottom: 7px;
}
.table-products .price {
  display: block;
  text-decoration: none;
  width: 50%;
  float: left;
  font-size: 0.875em;
}
.table-products .price > i {
  color: #8dc859;
}
.table-products .warranty {
  display: block;
  text-decoration: none;
  width: 50%;
  float: left;
  font-size: 0.875em;
}
.table-products .warranty > i {
  color: #f1c40f;
}
.table tbody > tr.table-line-fb > td {
  background-color: #9daccb;
  color: #262525;
}
.table tbody > tr.table-line-twitter > td {
  background-color: #9fccff;
  color: #262525;
}
.table tbody > tr.table-line-plus > td {
  background-color: #eea59c;
  color: #262525;
}
.table-stats .status-social-icon {
  font-size: 1.9em;
  vertical-align: bottom;
}
.table-stats .table-line-fb .status-social-icon {
  color: #556484;
}
.table-stats .table-line-twitter .status-social-icon {
  color: #5885b8;
}
.table-stats .table-line-plus .status-social-icon {
  color: #a75d54;
}

/* LECTURE MODALS */
.video-container {
  width: 100%;
  height: 100%;
}

.video-container iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* MOOC LECTURE CARD*/
.mooc-card {
  border: 2px solid #fff !important;
  color: #fff;
  background-color: #2da9e9 !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.mooc-card .card-header {
  text-align: center;
  border-bottom: 2px solid #fff !important;
}

.mooc-card .card-header .card-title {
  font-family: Norwester;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mooc-card .card-header .card-subtitle {
  font-weight: 700;
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mooc-card .card-header p {
  margin: 0 auto;
}

.mooc-card .card-body {
  padding: 0;
  height: 24vh;
  position: relative;
}

.mooc-card .card-body .mooc-video-container iframe {
  width: 100%;
  height: 24vh;
}


.mooc-card .card-body .mooc-video-container #spinner {
  position: absolute;
  top: 35%;
  left: 35%;
}

@font-face {
  font-family: Norwester;
  src: url(/static/media/norwester.696306af.otf);
}

@font-face {
  font-family: Poppins-Light;
  src: url(/static/media/Poppins-Light.f41b63c0.ttf);
}

body {
  font-family: Poppins-Light !important;
  background-color: #6a74ff !important;
}


/* MAIN CONTAINER*/
.banner { 
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
    background-image: url(/static/media/blue-curve.b49df8a8.svg);
    background-position: top;
    background-size: cover;
}

/* NAVBAR */
.banner .navbar-content {
    height: 10%;
}

.banner .navbar {
    padding-top: 15px;
    border: 0;
    z-index: 2;
}

.banner .navbar-brand {
    color: #fff !important;
    font-family: Norwester;
    font-size: 1.8em;
    font-weight: 700;
    margin-left: 5%;
}

.banner .navbar-nav {
    margin-right: 5%;
}

.banner .navbar-nav .nav-link {
    color: #fff !important;
    font-size: 1.2em;
}

/* PAGE CONTENT */
.banner .content {
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 90%;
}


/* INDEX PAGE*/

.banner .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner .info .app-title {
  font-family: Norwester;
}

.banner .info .app-subtitle {
  font-family: Poppins-Light;
  font-size: 24px;
  text-align: center;
}

.banner .info .index-img {
  width: 50%;
}

.banner .info h1 {
    font-size: 2.5em;
    font-weight: 700;
    color: #fff;
    letter-spacing: 2px;
}

.banner .info p {
    font-size: 2em;
    font-weight: 500;
    color: #FFFAF0;
    letter-spacing: 2px;
}

.banner .info button {
    color: #5864ff;
    padding: 10px 20px;
    font-size: 1.5em;
    font-weight: 600;
}

.banner .right-panel button {
    margin-right: 50%;
    transform: translateX(-50%);
    color: #5864ff;
    padding: 10px 20px;
    font-size: 1.5em;
    font-weight: 600;
}

.index-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0rem 5rem;
    margin-bottom: 20px;
  }
  
  .index-form .input-field {
    max-width: 380px;
    width: 100%;
    background-color: #f0f0f0;
    margin: 10px 0;
    height: 55px;
    border-radius: 55px;
    display: grid;
    grid-template-columns: 15% 85%;
    padding: 0 1.5rem 0 0;
    position: relative;
  }
  
  .index-form .input-field i {
    text-align: center;
    line-height: 55px;
    color: #acacac;
    font-size: 1.1rem;
  }

  .index-form .input-field .show-password {
    text-align: center;
    line-height: 55px;
    position: absolute;
    right: 30px;
  }
  
  .index-form .input-field input {
    background: none;
    outline: none;
    border: none;
    line-height: 1;
    font-weight: 600;
    font-size: 1.1rem;
    color: #333;
    height: 100%;
  }
  
  .index-form.input-field input:-ms-input-placeholder {
    color: #aaa;
    font-weight: 500;
  }
  
  .index-form.input-field input::placeholder {
    color: #aaa;
    font-weight: 500;
  }

/* ABOUT PAGE*/
.banner .about-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  padding: auto 50px;
}

.banner .about-left button {
  color: #5864ff;
  padding: 10px 20px;
  font-size: 1.5em;
  font-weight: 600;
}

.banner .about-left h2 {
  border-bottom: 2px solid #fff;
}

.banner .about-right {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner .about-right .about-img {
  width: 50%;
}

  /* HOME PAGE */
.home-section {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-section .home-card {
  height: 85%;
  width: 100%;
}

.home-card .categories {
    width: 100%;
    padding: 0;
    margin-left: 0;
  }
  
  .home-card .categories > div {
    float: left;
    width: 50%;
    padding: 10px 5px;
    font-size: 18px;
    text-align: center;
    border-right: 1px solid rgba(0,0,0,0.1);
    background-color: rgba(255,255,255,0.75);
    cursor: pointer;
    font-weight: 700;
  }
  
  .home-card .categories > div:nth-child(1) {
    color: #2da9e9;
    border-right-color: rgba(45,129,233,0.06);
    border-bottom: 4px solid #2da9e9;
    border-top-left-radius: 5px;
  }
  
  .home-card .categories > div:nth-child(1).active {
    color: #fff;
    background-color: #2da9e9;
    border-bottom: 4px solid rgba(0,0,0,0.15);
  }
  
  .home-card .categories > div:nth-child(2) {
    color: #0ec8a2;
    border-right-color: rgba(14,200,162,0.06);
    border-bottom: 4px solid #0ec8a2;
  }
  
  .home-card .categories > div:nth-child(2).active {
    color: #fff;
    background-color: #0ec8a2;
    border-bottom: 4px solid rgba(0,0,0,0.15);
  }

  /* MOOCs PAGE */
  .banner .mooc-title-section {
    text-align: center !important;
    color: #fff;
  }

  .banner .mooc-title-section .mooc-title {
    font-family: Norwester;
  }

  .banner .mooc-search {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 1.5rem;
    border: 2px solid #fff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 8px;
    padding: 5px 10px;
    color: #fff;
    background-color: #2da9e9 !important;
  }

  .banner .mooc-search form {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  
/* CHAT BOX */

:root {
	--dark-color-a: #667aff;
	--dark-color-b: #7386ff;
	--green-color-a: #ffd6cc;
	--green-color-b: #801a00;
	--light-color: #e6e9ff;
	--success-color: #5cb85c;
	--error-color: #d9534f;
}

.chat-container {
	height: 90%;
}

.chat-messages {
	height: 100%;
	padding: 10px;
	overflow-y: scroll;
}

.chat-messages .message {
	padding: 10px 10px;
	margin-bottom: 8px;
	background-color: #e6e9ff;
	background-color: var(--light-color);
	border-radius: 8px;
}

.chat-messages .question {
	padding: 10px 10px;
	margin-bottom: 8px;
	background-color: #ffd6cc;
	background-color: var(--green-color-a);
	border-radius: 8px;
}

.chat-messages .message .meta {
	font-size: 15px;
	font-weight: bold;
	color: #7386ff;
	color: var(--dark-color-b);
	opacity: 0.7;
	margin-bottom: 7px;
}

.chat-messages .question .meta {
	font-size: 15px;
	font-weight: bold;
	color: #801a00;
	color: var(--green-color-b);
	opacity: 0.7;
	margin-bottom: 7px;
}

.chat-messages .message .meta span {
	color: #777;
}

.chat-messages .question .meta .time {
	color: #777;
}

.chat-messages .question .meta span .badge {
	color: #fff;
}

.chat-form-container {
	margin-top: 0;
	padding: 5px 0px;
	background-color: #667aff;
	background-color: var(--dark-color-a);
}

.chat-form-container input[type='text'] {
	height: 40px;
	font-size: 16px;
	padding: 10px;
}

.chat-form-container button {
	height: 40px;
}

/* QUESTION MODAL */
.box-header.with-border {
    border-bottom: 1px solid #f4f4f4;
}
.box-header {
    color: #444;
    padding-bottom: 10px;
}

.user-block .username {
    font-size: 16px;
    font-weight: 600;
    color: blue;
}
.user-block .description {
    color: #999;
    font-size: 13px;
    margin-left: 10px;
}

.box-body {
    padding: 10px;
}
.box-comments {
    background: #f7f7f7 !important;
}
.box-footer {
    border-top: 1px solid #f4f4f4;
    padding: 10px;
    background-color: #fff;
}
.box-comments .box-comment:first-of-type {
    padding-top: 0;
}
.box-comments .box-comment {
    padding: 8px 0;
    border-bottom: 1px solid #eee;
}
.box-comments .comment-text {
    margin-left: 20px;
    color: #555;
}
.box-comments .username {
    color: #444;
    display: block;
    font-weight: 600;
}
.box-comments .text-muted {
    font-weight: 400;
    font-size: 12px;
    margin-left: 10px;
}
/* PARTICIPANTS BOX */
.participants-box {
  height: 100%;
  overflow-y: scroll;
}

/* USER CARD */

.widget {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  max-height: 35px;
}

.widget span {
  display: flex;
  margin: 0;
  align-items: center;
  max-height: 100%;
}

.lazur-bg {
  background-color: #23c6c8;
  color: #ffffff;
}

.yellow-bg {
  background-color: #f8ac59;
  color: #ffffff;
}

.notes-list {
    height: 100%;
    overflow-x: scroll;
}

.editor {
   min-height: 90%; 
   max-height: 90%;
   margin-bottom: 2px;
   overflow-y: scroll;
}

.boxed {
    border: 3px solid blue;
}

.note {
    padding: 20px;
    border-radius: 20px;
    height: 100%;
    min-width: 40%;
    max-width: 40%;
    overflow-y: scroll;
    margin-bottom: 5px;
    margin-right: 10px;
}
.b-profile {
    padding-bottom: 20px;
    color: #ffffff;
    margin-bottom:20px;
}
.b-profile-name {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
}

.b-profile .btn-follow-this {
    font-size: 16px;
    line-height: 34px;
    border: 1px solid #ffffff;
    color: #ffffff;
    padding: 0 40px;
    text-transform: uppercase;
    display: inline-block;
    cursor: pointer;
}
.m-b-md {
    margin-bottom: 20px;
}
.text-c {
    text-align: center;
}
.decor-success {
    background-color: #46be8a;
}
.decor-warning {
    background: #f2a654;
}
.decor-default {
    background: #7266ba;
}
.decor-info {
    background: #39bee8;
}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}

/* NOTE CARD */
.note-card-big-shadow {
    min-width: 320px;
    max-width: 320px;
    position: relative;
}

.coloured-cards .card {
    margin-top: 30px;
}

.card[data-radius="none"] {
    border-radius: 0px;
}
.note-card {
    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
    background-color: #FFFFFF;
    color: #252422;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    margin-right: 20px;
    min-height: 95%;
}

.reactions {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 10px;
    color: white;
}

.card[data-background="image"] .title, .card[data-background="image"] .stats, .card[data-background="image"] .category, .card[data-background="image"] .description, .card[data-background="image"] .note-content, .card[data-background="image"] .card-footer, .card[data-background="image"] small, .card[data-background="image"] .note-content a, .card[data-background="color"] .title, .card[data-background="color"] .stats, .card[data-background="color"] .category, .card[data-background="color"] .description, .card[data-background="color"] .note-content, .card[data-background="color"] .card-footer, .card[data-background="color"] small, .card[data-background="color"] .note-content a {
    color: #FFFFFF;
}
.note-card.card-just-text .note-content {
    padding: 30px 65px;
    text-align: center;
}
.note-card .note-content {
    padding: 20px 20px 10px 20px;
}
.card[data-color="blue"] .category {
    color: #7a9e9f;
}

.note-card .category, .card .label {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
}
.note-card-big-shadow:before {
    bottom: -12%;
    content: "";
    display: block;
    left: -12%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}
h4, .h4 {
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.2em;
}
h6, .h6 {
    font-size: 0.9em;
    font-weight: 600;
    text-transform: uppercase;
}
.card .description {
    font-size: 16px;
    color: #66615b;
}
.content-card{
    margin-top:30px;    
}
a:hover, a:focus {
    text-decoration: none;
}

/*======== COLORS ===========*/
.card[data-color="blue"] {
    background: #b8d8d8;
}
.card[data-color="blue"] .description {
    color: #506568;
}

.card[data-color="green"] {
    background: #d5e5a3;
}
.card[data-color="green"] .description {
    color: #60773d;
}
.card[data-color="green"] .category {
    color: #92ac56;
}

.card[data-color="yellow"] {
    background: #ffe28c;
}
.card[data-color="yellow"] .description {
    color: #b25825;
}
.card[data-color="yellow"] .category {
    color: #d88715;
}

.card[data-color="brown"] {
    background: #d6c1ab;
}
.card[data-color="brown"] .description {
    color: #75442e;
}
.card[data-color="brown"] .category {
    color: #a47e65;
}

.card[data-color="purple"] {
    background: #baa9ba;
}
.card[data-color="purple"] .description {
    color: #3a283d;
}
.card[data-color="purple"] .category {
    color: #5a283d;
}

.card[data-color="orange"] {
    background: #ff8f5e;
}
.card[data-color="orange"] .description {
    color: #772510;
}
.card[data-color="orange"] .category {
    color: #e95e37;
}
/* DISABLED FUNCTIONALITY CARD (NOTES, CHAT, FEEDBACKS)*/
.disabled-card {
  /* The image used */
  background-image: url(/static/media/disabled.6d089183.jpg);

  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Position text in the middle of the page/image */
.disabled-card-text {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}

/* DASHBOARD */
.tab-title {
  width: 100%;
  padding: 0;
  margin-left: 0;
}

.tab-title-reverse {
  width: 100%;
  padding: 0;
  margin-left: 0;
}

.tab-title-reverse > div {
  float: left;
  padding: 10px 5px;
  font-size: 16px;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.75);
  font-weight: 700;
  width: 100%;
  height: 55px;
}

.tab-title-reverse > div:first-child {
  color: #2da9e9;
  border-right-color: rgba(45,129,233,.06);
  border-top: 4px solid #2da9e9;
  border-top-left-radius: 5px;
}

.tab-title > div {
  float: left;
  padding: 10px 5px;
  font-size: 18px;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  font-weight: 700;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.tab-title.single > div {
  width: 100%;
}

.tab-title.double > div {
  width: 50%;
}

.tab-title.triple > div {
  width: 33.333%;
}

.tab-title > div:nth-child(2) {
  color: #2da9e9;
  border-right-color: rgba(45, 129, 233, 0.06);
  border-bottom: 4px solid #2da9e9;
  border-top-left-radius: 5px;
}

.tab-title > div:nth-child(2).active {
  color: #fff;
  background-color: #2da9e9;
  border-bottom: 4px solid rgba(0, 0, 0, 0.15);
}

.tab-title > div:nth-child(1) {
  color: #0ec8a2;
  border-right-color: rgba(14, 200, 162, 0.06);
  border-bottom: 4px solid #0ec8a2;
}

.tab-title > div:nth-child(1).active {
  color: #fff;
  background-color: #0ec8a2;
  border-bottom: 4px solid rgba(0, 0, 0, 0.15);
}

.tab-title > div:nth-child(3) {
  color: #ff9e2a;
  border-right-color: rgba(255, 152, 14, 0.06);
  border-bottom: 4px solid #ff9e2a;
}

.tab-title > div:nth-child(3).active {
  color: #fff;
  background-color: #ff9e2a;
  border-bottom: 4px solid rgba(0, 0, 0, 0.15);
}

.tab-title > div:nth-child(4) {
  color: #314557;
  border-bottom: 4px solid #314557;
  border-right-color: transparent;
}

.tab-title > div:nth-child(4).active {
  color: #fff;
  background-color: #314557;
  border-bottom: 4px solid rgba(0, 0, 0, 0.35);
}

/* USER'S FEEDBACK PROGRESS BAR */
.feedback-pb {
  margin-right: 0.9rem;
  margin-left: 2.2rem;
  height: 0.7rem !important;
}

/* FEEDBACK DASHBOARD */
.feedback-btn {
  position: absolute;
  right: 8px;
}

/* DASHBOARD DIMENSION */
.card-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.communication-card {
  height: 40vh;
}

.feedback-card {
  height: 40vh;
}

.video-card {
  min-height: 46vh;
  max-height: 46vh;
  border-radius: 0 !important;
  border: 3px solid #fff !important;
}

.chart-container {
  height: 400px;
  max-height: 400px;
}

.big-card-content-scrollable {
  overflow-y: scroll;
  min-height: 40vh;
  max-height: 40vh;
}

.big-card-content {
  min-height: 40vh;
  max-height: 40vh;
}

.little-card-content {
  min-height: 16vh;
  max-height: 16vh;
}
/* UNDERSTANDING LOW LEVEL SIGNAL */
.signal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.signal {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 23vh;
}

.signal .text {
    position: absolute;
    font-weight: 700;
    font-size: 1.5em;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    pointer-events: none;
}

.signal .text-red {
    color: red;
    animation: animate-text-warning 7.5s linear infinite;
}

.signal .text span {
    position: absolute;
    top: -100px;
    text-transform: uppercase; 
    display: inline-block;
    transform-origin: 0 100px;
}

.signal .big-small {
    animation: animate-img 5s linear infinite;
}

@keyframes animate-text-warning {
    0% {
        transform: rotate(360deg);
        text-shadow: 0 0 16px red;
    }
    12.5% { text-shadow: 0 0 16px orange; }
    25% { text-shadow: 0 0 16px red; }
    37.5% { text-shadow: 0 0 16px orange; }
    50% { text-shadow: 0 0 16px red; }
    62.5% { text-shadow: 0 0 16px orange; }
    75% { text-shadow: 0 0 16px red; }
    87.5% { text-shadow: 0 0 16px orange; }
    100% {
        transform: rotate(0deg);
        text-shadow: 0 0 16px red;
    }
}

@keyframes animate-img {
    0% { width: 85px; height: 85px; }
    12.5% { width: 105px; height: 105px; }
    25% { width: 85px; height: 85px; }
    37.5% { width: 105px; height: 105px; }
    50% { width: 85px; height: 85px; }
    62.5% { width: 105px; height: 105px; }
    75% { width: 85px; height: 85px; }
    87.5% { width: 105px; height: 105px; }
    100% { width: 85px; height: 85px; }
}
/* VIDEO CARD */
.card-video-container iframe { 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
@media (prefers-reduced-motion: no-preference) {
    #loader {
        animation: Loader-logo-spin infinite 5s linear;
    }
  }
#pdf-section {
    overflow-y: scroll;
}

.control-panel {
    font-size: 14px;
    color: black;
    background-color:white;
    border-radius: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.disabled {
    cursor: not-allowed;
    color: lightgray;
}

.clickable {
    cursor: pointer;
}

@keyframes Loader-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}
