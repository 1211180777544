.notes-list {
    height: 100%;
    overflow-x: scroll;
}

.editor {
   min-height: 90%; 
   max-height: 90%;
   margin-bottom: 2px;
   overflow-y: scroll;
}

.boxed {
    border: 3px solid blue;
}

.note {
    padding: 20px;
    border-radius: 20px;
    height: 100%;
    min-width: 40%;
    max-width: 40%;
    overflow-y: scroll;
    margin-bottom: 5px;
    margin-right: 10px;
}
.b-profile {
    padding-bottom: 20px;
    color: #ffffff;
    margin-bottom:20px;
}
.b-profile-name {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
}

.b-profile .btn-follow-this {
    font-size: 16px;
    line-height: 34px;
    border: 1px solid #ffffff;
    color: #ffffff;
    padding: 0 40px;
    text-transform: uppercase;
    display: inline-block;
    cursor: pointer;
}
.m-b-md {
    margin-bottom: 20px;
}
.text-c {
    text-align: center;
}
.decor-success {
    background-color: #46be8a;
}
.decor-warning {
    background: #f2a654;
}
.decor-default {
    background: #7266ba;
}
.decor-info {
    background: #39bee8;
}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}

/* NOTE CARD */
.note-card-big-shadow {
    min-width: 320px;
    max-width: 320px;
    position: relative;
}

.coloured-cards .card {
    margin-top: 30px;
}

.card[data-radius="none"] {
    border-radius: 0px;
}
.note-card {
    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
    background-color: #FFFFFF;
    color: #252422;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
    margin-right: 20px;
    min-height: 95%;
}

.reactions {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 10px;
    color: white;
}

.card[data-background="image"] .title, .card[data-background="image"] .stats, .card[data-background="image"] .category, .card[data-background="image"] .description, .card[data-background="image"] .note-content, .card[data-background="image"] .card-footer, .card[data-background="image"] small, .card[data-background="image"] .note-content a, .card[data-background="color"] .title, .card[data-background="color"] .stats, .card[data-background="color"] .category, .card[data-background="color"] .description, .card[data-background="color"] .note-content, .card[data-background="color"] .card-footer, .card[data-background="color"] small, .card[data-background="color"] .note-content a {
    color: #FFFFFF;
}
.note-card.card-just-text .note-content {
    padding: 30px 65px;
    text-align: center;
}
.note-card .note-content {
    padding: 20px 20px 10px 20px;
}
.card[data-color="blue"] .category {
    color: #7a9e9f;
}

.note-card .category, .card .label {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
}
.note-card-big-shadow:before {
    bottom: -12%;
    content: "";
    display: block;
    left: -12%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}
h4, .h4 {
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.2em;
}
h6, .h6 {
    font-size: 0.9em;
    font-weight: 600;
    text-transform: uppercase;
}
.card .description {
    font-size: 16px;
    color: #66615b;
}
.content-card{
    margin-top:30px;    
}
a:hover, a:focus {
    text-decoration: none;
}

/*======== COLORS ===========*/
.card[data-color="blue"] {
    background: #b8d8d8;
}
.card[data-color="blue"] .description {
    color: #506568;
}

.card[data-color="green"] {
    background: #d5e5a3;
}
.card[data-color="green"] .description {
    color: #60773d;
}
.card[data-color="green"] .category {
    color: #92ac56;
}

.card[data-color="yellow"] {
    background: #ffe28c;
}
.card[data-color="yellow"] .description {
    color: #b25825;
}
.card[data-color="yellow"] .category {
    color: #d88715;
}

.card[data-color="brown"] {
    background: #d6c1ab;
}
.card[data-color="brown"] .description {
    color: #75442e;
}
.card[data-color="brown"] .category {
    color: #a47e65;
}

.card[data-color="purple"] {
    background: #baa9ba;
}
.card[data-color="purple"] .description {
    color: #3a283d;
}
.card[data-color="purple"] .category {
    color: #5a283d;
}

.card[data-color="orange"] {
    background: #ff8f5e;
}
.card[data-color="orange"] .description {
    color: #772510;
}
.card[data-color="orange"] .category {
    color: #e95e37;
}