@media (prefers-reduced-motion: no-preference) {
    #loader {
        animation: Loader-logo-spin infinite 5s linear;
    }
  }
#pdf-section {
    overflow-y: scroll;
}

.control-panel {
    font-size: 14px;
    color: black;
    background-color:white;
    border-radius: 5px;
    width: fit-content;
}

.disabled {
    cursor: not-allowed;
    color: lightgray;
}

.clickable {
    cursor: pointer;
}

@keyframes Loader-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}