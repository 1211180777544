/* COURSE DETAIL CARD*/
.card-box {
  position: relative;
  color: #fff;
  padding: 5px 10px 5px;
  margin: 0px 0px;
  background-color: #00c0ef !important;
}
.card-box:hover {
  text-decoration: none;
  color: #f1f1f1;
}
.card-box:hover .icon i {
  font-size: 80px;
  transition: 1s;
  -webkit-transition: 1s;
}
.card-box .inner {
  padding: 5px 10px 0 10px;
}
.card-box h3 {
  font-size: 27px;
  font-weight: bold;
  margin: 0 0 8px 0;
  white-space: nowrap;
  padding: 0;
  text-align: left;
}
.card-box p {
  font-size: 15px;
}
.card-box .icon {
  position: absolute;
  top: auto;
  bottom: 0px;
  right: 5px;
  z-index: 0;
  font-size: 60px;
  color: rgba(0, 0, 0, 0.15);
}

/* COURSE CARD */
.card-big-shadow {
    max-width: 320px;
    position: relative;
}

.coloured-cards .card {
    margin-top: 30px;
}

.card[data-radius="none"] {
    border-radius: 0px;
}
.card {
    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
    background-color: #FFFFFF;
    color: #252422;
    margin-bottom: 20px;
    position: relative;
    z-index: 1;
}

.clickable {
    cursor: pointer;
}

.card .title {
    text-transform: uppercase;
    line-height: 1.1rem;
    font-size: 20px;
    max-height: 5.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
}

.card[data-background="image"] .title, .card[data-background="image"] .stats, .card[data-background="image"] .category, .card[data-background="image"] .description, .card[data-background="image"] .content, .card[data-background="image"] .card-footer, .card[data-background="image"] small, .card[data-background="image"] .content a, .card[data-background="color"] .title, .card[data-background="color"] .stats, .card[data-background="color"] .category, .card[data-background="color"] .description, .card[data-background="color"] .content, .card[data-background="color"] .card-footer, .card[data-background="color"] small, .card[data-background="color"] .content a {
    color: #FFFFFF;
}

.card.card-just-text .content {
    padding: 30px 30px;
    text-align: center;
}
.card .content {
    padding: 10px 10px 5px 10px;
}

.card[data-color="blue"] .category {
    color: #7a9e9f;
}

.card .category, .card .label {
    font-size: 14px;
    margin-bottom: 0px;
}
.card-big-shadow:before {
    bottom: -12%;
    content: "";
    display: block;
    left: -12%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}

.copy {
    position: absolute;
    top: 0px;
    right: 25px;
    font-size: 20px;
    padding: 10px;
}

.students {
    position: absolute;
    top: 0px;
    right: 50px;
    font-size: 20px;
    padding: 10px;
}

.grow:hover {
    font-size: 25px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    cursor: pointer;
}

.delete {
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 20px;
    padding: 10px;
}

h4, .h4 {
    font-size: 1.5em;
    font-weight: 600;
    line-height: 1.2em;
}
h6, .h6 {
    font-size: 0.9em;
    font-weight: 600;
    text-transform: uppercase;
}
.card .description {
    font-size: 16px;
    color: #66615b;
}
.content-card{
    margin-top:30px;    
}
a:hover, a:focus {
    text-decoration: none;
}

.card[data-color="blue"] {
    background: #b8d8d8;
}
.card[data-color="blue"] .description {
    color: #506568;
}

/* TOTAL COUNT OF COURSES*/
.total {
    border-bottom: 3px solid #0ec8a2;
  }

/* CREATE COURSE FORM */
.course-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 3px solid #0ec8a2;
    padding: 10px 10px;
    border-radius: 8px;
}

.course-form h5 {
    border-bottom: 3px solid #0ec8a2;
    text-transform: uppercase;
    width: fit-content;
}