/* PARTICIPANTS BOX */
.participants-box {
  height: 100%;
  overflow-y: scroll;
}

/* USER CARD */

.widget {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  max-height: 35px;
}

.widget span {
  display: flex;
  margin: 0;
  align-items: center;
  max-height: 100%;
}

.lazur-bg {
  background-color: #23c6c8;
  color: #ffffff;
}

.yellow-bg {
  background-color: #f8ac59;
  color: #ffffff;
}
