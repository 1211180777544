/* CHAT BOX */
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

:root {
	--dark-color-a: #667aff;
	--dark-color-b: #7386ff;
	--green-color-a: #ffd6cc;
	--green-color-b: #801a00;
	--light-color: #e6e9ff;
	--success-color: #5cb85c;
	--error-color: #d9534f;
}

.chat-container {
	height: 90%;
}

.chat-messages {
	height: 100%;
	padding: 10px;
	overflow-y: scroll;
}

.chat-messages .message {
	padding: 10px 10px;
	margin-bottom: 8px;
	background-color: var(--light-color);
	border-radius: 8px;
}

.chat-messages .question {
	padding: 10px 10px;
	margin-bottom: 8px;
	background-color: var(--green-color-a);
	border-radius: 8px;
}

.chat-messages .message .meta {
	font-size: 15px;
	font-weight: bold;
	color: var(--dark-color-b);
	opacity: 0.7;
	margin-bottom: 7px;
}

.chat-messages .question .meta {
	font-size: 15px;
	font-weight: bold;
	color: var(--green-color-b);
	opacity: 0.7;
	margin-bottom: 7px;
}

.chat-messages .message .meta span {
	color: #777;
}

.chat-messages .question .meta .time {
	color: #777;
}

.chat-messages .question .meta span .badge {
	color: #fff;
}

.chat-form-container {
	margin-top: 0;
	padding: 5px 0px;
	background-color: var(--dark-color-a);
}

.chat-form-container input[type='text'] {
	height: 40px;
	font-size: 16px;
	padding: 10px;
}

.chat-form-container button {
	height: 40px;
}

/* QUESTION MODAL */
.box-header.with-border {
    border-bottom: 1px solid #f4f4f4;
}
.box-header {
    color: #444;
    padding-bottom: 10px;
}

.user-block .username {
    font-size: 16px;
    font-weight: 600;
    color: blue;
}
.user-block .description {
    color: #999;
    font-size: 13px;
    margin-left: 10px;
}

.box-body {
    padding: 10px;
}
.box-comments {
    background: #f7f7f7 !important;
}
.box-footer {
    border-top: 1px solid #f4f4f4;
    padding: 10px;
    background-color: #fff;
}
.box-comments .box-comment:first-of-type {
    padding-top: 0;
}
.box-comments .box-comment {
    padding: 8px 0;
    border-bottom: 1px solid #eee;
}
.box-comments .comment-text {
    margin-left: 20px;
    color: #555;
}
.box-comments .username {
    color: #444;
    display: block;
    font-weight: 600;
}
.box-comments .text-muted {
    font-weight: 400;
    font-size: 12px;
    margin-left: 10px;
}