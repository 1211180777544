/* DISABLED FUNCTIONALITY CARD (NOTES, CHAT, FEEDBACKS)*/
.disabled-card {
  /* The image used */
  background-image: url("../../assets/images/disabled.jpg");

  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Position text in the middle of the page/image */
.disabled-card-text {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}

/* DASHBOARD */
.tab-title {
  width: 100%;
  padding: 0;
  margin-left: 0;
}

.tab-title-reverse {
  width: 100%;
  padding: 0;
  margin-left: 0;
}

.tab-title-reverse > div {
  float: left;
  padding: 10px 5px;
  font-size: 16px;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.75);
  font-weight: 700;
  width: 100%;
  height: 55px;
}

.tab-title-reverse > div:first-child {
  color: #2da9e9;
  border-right-color: rgba(45,129,233,.06);
  border-top: 4px solid #2da9e9;
  border-top-left-radius: 5px;
}

.tab-title > div {
  float: left;
  padding: 10px 5px;
  font-size: 18px;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  font-weight: 700;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.tab-title.single > div {
  width: 100%;
}

.tab-title.double > div {
  width: 50%;
}

.tab-title.triple > div {
  width: 33.333%;
}

.tab-title > div:nth-child(2) {
  color: #2da9e9;
  border-right-color: rgba(45, 129, 233, 0.06);
  border-bottom: 4px solid #2da9e9;
  border-top-left-radius: 5px;
}

.tab-title > div:nth-child(2).active {
  color: #fff;
  background-color: #2da9e9;
  border-bottom: 4px solid rgba(0, 0, 0, 0.15);
}

.tab-title > div:nth-child(1) {
  color: #0ec8a2;
  border-right-color: rgba(14, 200, 162, 0.06);
  border-bottom: 4px solid #0ec8a2;
}

.tab-title > div:nth-child(1).active {
  color: #fff;
  background-color: #0ec8a2;
  border-bottom: 4px solid rgba(0, 0, 0, 0.15);
}

.tab-title > div:nth-child(3) {
  color: #ff9e2a;
  border-right-color: rgba(255, 152, 14, 0.06);
  border-bottom: 4px solid #ff9e2a;
}

.tab-title > div:nth-child(3).active {
  color: #fff;
  background-color: #ff9e2a;
  border-bottom: 4px solid rgba(0, 0, 0, 0.15);
}

.tab-title > div:nth-child(4) {
  color: #314557;
  border-bottom: 4px solid #314557;
  border-right-color: transparent;
}

.tab-title > div:nth-child(4).active {
  color: #fff;
  background-color: #314557;
  border-bottom: 4px solid rgba(0, 0, 0, 0.35);
}

/* USER'S FEEDBACK PROGRESS BAR */
.feedback-pb {
  margin-right: 0.9rem;
  margin-left: 2.2rem;
  height: 0.7rem !important;
}

/* FEEDBACK DASHBOARD */
.feedback-btn {
  position: absolute;
  right: 8px;
}

/* DASHBOARD DIMENSION */
.card-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.communication-card {
  height: 40vh;
}

.feedback-card {
  height: 40vh;
}

.video-card {
  min-height: 46vh;
  max-height: 46vh;
  border-radius: 0 !important;
  border: 3px solid #fff !important;
}

.chart-container {
  height: 400px;
  max-height: 400px;
}

.big-card-content-scrollable {
  overflow-y: scroll;
  min-height: 40vh;
  max-height: 40vh;
}

.big-card-content {
  min-height: 40vh;
  max-height: 40vh;
}

.little-card-content {
  min-height: 16vh;
  max-height: 16vh;
}